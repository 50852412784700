<template xmlns="http://www.w3.org/1999/html">
  <v-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ props }">
      <v-list-item v-bind="props" @click="getTimeSpent">
        <v-list-item-media class="mx-auto">
          <div v-if="contrat">
            <v-icon color="blue">mdi-account-box-multiple</v-icon>
          </div>
          <div v-else>
            <v-icon color="red">mdi-account-box-multiple</v-icon>
          </div>
        </v-list-item-media>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title>
        <div v-if="!contrat">
          <span>
            <v-icon color="red" class="mr-1">mdi-pencil</v-icon>
            {{ `Information contrat (#${id})` }}
          </span>
        </div>
        <div v-else>
          <span>
            <v-icon color="blue" class="mr-1">mdi-pencil</v-icon>
            {{ `Information contrat (#${id}) : ${nom}` }}
          </span>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>

      <div v-if="contrat">
        <div v-if="!editing">
          <v-card-text>
            heures: {{ heures }}<br>
            budget: {{ budget == null ? 0 : budget }}<br>
            heures effectuées: {{ timespent }}<br>
            heures effectuées N-1: {{ timespentMinusOne }}<br>
            date anniversaire: {{ date }}<br>
            qui: {{ qui }}<br>
            actions: {{ actions }}<br>
            <div v-if="infos">
              infos: {{ infos }}<br>
            </div>
          </v-card-text>
        </div>
        <div v-else>
          <v-card-text>
            <p>Heures <input type="number" v-model="newHeures" :placeholder="heures"/></p>
            <p>Budget <input type="number" v-model="newBudget" :placeholder="budget"/></p>
            <p>Date anniversaire <input type="text" v-model="newDate" :placeholder="date"/></p>
            <p>Qui <input type="text" v-model="newQui" :placeholder="qui"/></p>
            <p>Actions <input type="text" v-model="newAction" :placeholder="actions"/></p>
            <p>Infos <input type="text" v-model="newInfos" :placeholder="infos"/></p>
          </v-card-text>
        </div>
      </div>
      <div v-else>
        <v-card-text>
          Il n'y a pas de contrat enregistré pour ce site.
        </v-card-text>
      </div>
      <v-card-actions v-if="!editing">
        <v-btn variant="text" @click="editing = true">Modifier</v-btn>
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="closeAndUpdate">Fermer</v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-btn variant="text" @click="updateContrat">Enregistrer</v-btn>
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="editing = false">Annuler</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

let timespent = 0;
export default {
  name: "Contrat",
  props: {
    nom: {
      type: String,
      default: null,
    },
    id: {
      type: Number,
      default: null,
    },
    heures: {
      type: String,
      default: null,
    },
    qui: {
      type: String,
      default: null,
    },
    actions: {
      type: String,
      default: null,
    },
    infos: {
      type: String,
      default: null,
    },
    contrat: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    budget: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      dialog: false,
      editing: false,
      timespent: 0,
      timespentMinusOne: 0,
      newHeures: null,
      newBudget: null,
      newDate: null,
      newQui: null,
      newAction: null,
      newInfos: null,
    };
  },
  methods: {
    closeAndUpdate() {
      this.dialog = false;
      this.$emit('update');
    },
    getTimeSpent() {
      // fait une requête au back pour récuperer le temps passé par tickets avec l'api Jira
      if (this.date == null) {
        return;
      }
      const params = {
        projet: this.nom,
        date: this.date
      };
      axios.get('/v1/sites/jira', { params })
        .then((response) => {
          if (response.data.erreur) {
            this.timespent = 0;
            this.timespentMinusOne = 0;
            console.log(`[ERREUR TICKET]: ${response.data.erreur}`);
            return;
          }
          this.timespent = response.data.timespent;
          this.timespentMinusOne = response.data.timespentMinusOne;
        })
        .catch(this.logResponseErrors);
    },
    updateContrat() {
      const params = {
        id: this.id,
        heures: this.newHeures,
        budget: this.newBudget,
        date: this.newDate,
        actions: this.newAction,
        qui: this.newQui,
        infos: this.newInfos,
      }
      axios.post('/v1/sites/contrat', params)
        .then((response) => {
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        })
      this.dialog = false;
      this.editing = false;
      this.$emit('update');
      this.newHeures = null;
      this.newBudget = null;
      this.newDate = null;
      this.newAction = null;
      this.newQui = null;
      this.newInfos = null;
      location.reload()
    },
  },
  watch: {
    dialog(val) {
      if (!val) this.$emit('update');
    }
  }
};
</script>
