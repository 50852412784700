import {createRouter, createWebHashHistory} from 'vue-router'
import Home from "../pages/index/components/Home.vue";
import TableSite from "../pages/index/components/TableSite.vue";
import Agenda from "../pages/index/components/Agenda.vue";
import Type from "../pages/index/components/Type.vue";
import Validation from "../pages/index/components/Validation.vue";
import Export from "../pages/index/components/Export.vue";
import HeuresSupplementaires from "../pages/index/components/HeuresSupplementaires.vue";

const router = createRouter({
    routes: [
        {
            path: '/home',
            component: Home
        },
        {
            path: '/',
            component: TableSite
        },
        {
            path: '/sites',
            component: TableSite
        },
        {
            path: '/agenda',
            component: Agenda
        },
        {
            path: '/gestionType',
            component: Type
        },
        {
            path: '/validation',
            component: Validation
        },
        {
            path: '/export',
            component: Export
        },
        {
            path: '/heures-supp',
            component: HeuresSupplementaires
        },
    ],
    history: createWebHashHistory(),
})

export default router