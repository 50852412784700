<template>
  <v-dialog v-model="dialog" width="1000">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-media class="mx-auto">
          <v-icon color="blue">mdi-book-multiple</v-icon>
        </v-list-item-media>
      </v-list-item>
    </template>

    <!-- INFO QUAND CLIQUE -->
    <v-card>
      <v-card-title>
        <span>
          <v-icon color="blue">mdi-book-multiple</v-icon>
          {{ urlSite }}
        </span>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-list two-line>
          <v-list-item v-for="(notebook, i) in notebooks" :key="i">
            <v-list-item-media @click="visit(baseURL + notebook.id)">
              <v-icon color="blue">mdi-link</v-icon>
            </v-list-item-media>
            <v-list-item>
              <v-list-item-title>{{ notebook.name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                notebook.description
              }}</v-list-item-subtitle>
              <span v-html="notebook.contents"></span>
            </v-list-item>
          </v-list-item>

        <v-card-actions>
          <v-btn class="ml-auto" color="primary" text @click="dialog = false"
            >Fermer</v-btn
          >
        </v-card-actions>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Notebook",
  props: {
    notebooks: {
      type: Array,
      default: null,
    },
    urlSite: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      baseURL: "https://kairios.teamwork.com/#notebooks/",
      dialog: false,
    };
  },
  methods: {
    visit(url) {
      window.open(url, "_blank");
    },
  },
};
</script>