<template>
  <v-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ props }">
      <v-list-item v-bind="props">
        <v-list-item-media>
          <v-icon color="blue">mdi-pencil</v-icon>
        </v-list-item-media>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title>
        <div v-if="!nom">
          <span>
            <v-icon color="red" class="mr-1">mdi-pencil</v-icon>
            {{ `Information site (#${id})` }}
          </span>
        </div>
        <div v-else>
          <span>
            <v-icon color="blue" class="mr-1">mdi-pencil</v-icon>
            {{ `Information site (#${id}) : ${nom}` }}
          </span>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text>
        <iframe
            title="Form Admin Site"
            width="100%"
            height="650"
            :src="'admin?crudAction=edit&crudControllerFqcn=App%5CController%5CAdmin%5CSiteCrudController&entityId='+ id">
        </iframe>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="closeAndUpdate">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Site",
  props: {
    nom: {
      type: String,
      default: null,
    },
    id: {
      type: Number,
      default: null,
    },
    url: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeAndUpdate() {
      this.dialog = false;
      this.$emit('update');
    },
  },
  watch: {
    dialog(val) {
      if (!val) this.$emit('update');
    }
  }
};
</script>
