<template>
  <v-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on }">
      <v-list-item @click="this.dialog = true" class="pl-0">
        <v-chip>
          <!-- Si il n'y a pas de nom pour le Client  -->
          <div v-if="!client.nomClient">
            <v-icon color="red">mdi-pencil</v-icon>
          </div>
          <div v-else>
            {{ client.nomClient }}
          </div>
        </v-chip>
      </v-list-item>
    </template>

    <!-- INFO QUAND CLIQUE -->
    <v-card>
      <v-card-title>
          <span>
              <!-- Si il n'y a pas de nom pour le Client  -->
              <div v-if="!client.nomClient">
                  <v-icon color="red" class="mr-1">mdi-pencil</v-icon>
                  {{ "Information client (#"+ client.id +")"}}
              </div>
              <div v-else >
                  <v-icon color="blue" class="mr-1">mdi-pencil</v-icon>
                  {{ "Information client (#"+ client.id +") : " + client.nomClient }}
              </div>
          </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <iframe
          title="Form Admin Client"
          width="100%"
          height="450"
          :src="'/admin?crudAction=edit&crudControllerFqcn=App%5CController%5CAdmin%5CClientCrudController&entityId='+ client.id">
      </iframe>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeAndUpdate" >Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "client",
  props: {
    client: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeAndUpdate() {
      this.dialog = false;
      this.$emit('update');
    },
  },
  watch: {
    dialog (val) {
      this.$emit('update');
    }
  }
};
</script>