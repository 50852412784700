<template>
  <v-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on }">
      <!-- Si il n'y a pas de nom pour le contact  -->
      <v-list-item @click="this.dialog = true" class="pl-0">
        <v-list-item v-if="!contact.nom">
          <v-list-item-media>
            <v-icon color="red">mdi-account</v-icon>
          </v-list-item-media>
        </v-list-item>
        <v-list-item v-else >
          <v-list-item-media>
            <v-icon color="blue">mdi-account</v-icon>
          </v-list-item-media>
        </v-list-item>
      </v-list-item>
    </template>

    <!-- INFO QUAND CLIQUE -->
    <v-card>
      <v-card-title>
        <!-- Si il n'y a pas de nom pour le Client  -->
        <div v-if="!contact.nom">
          <v-icon color="red" class="mr-1">mdi-account</v-icon>
          {{ "Information contact (#"+ contact.id +")"}}
        </div>
        <div v-else >
          <v-icon color="blue" class="mr-1">mdi-account</v-icon>
          {{ "Information contact (#"+ contact.id +") : " + contact.nom }}
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <iframe
        title="Form Admin Contact"
        width="100%"
        height="550"
        :src="'/admin?crudAction=edit&crudControllerFqcn=App%5CController%5CAdmin%5CContactCrudController&entityId='+contact.id">
      </iframe>

      <v-list two-line>
        <!-- TEL -->
        <v-list-item @click="copyToClipboard(contact.tel)">
          <v-list-item-media>
            <v-icon color="blue">mdi-phone</v-icon>
          </v-list-item-media>
          <v-list-item-media>
            <v-list-item-title>{{contact.tel}}</v-list-item-title>
            <v-list-item-subtitle>Portable</v-list-item-subtitle>
          </v-list-item-media>
        </v-list-item>

        <!-- MAIL -->
        <v-list-item @click="copyToClipboard(contact.mail)">
          <v-list-item-media>
            <v-icon color="blue">mdi-email</v-icon>
          </v-list-item-media>
          <v-list-item>
            <v-list-item-title>{{contact.mail}}</v-list-item-title>
            <v-list-item-subtitle>E-mail</v-list-item-subtitle>
          </v-list-item>
        </v-list-item>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeAndUpdate" >Fermer</v-btn>
        </v-card-actions>
      </v-list>

      <v-snackbar v-model="snackbar" :timeout="timeout" top>
        Copié dans le Presse-papier !
        <!-- <v-btn color="white" text @click="snackbar = false">Fermer</v-btn> -->
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "contact",
  props: {
    contact: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      snackbar: false,
      timeout: 1000
    };
  },
  methods: {
    copyToClipboard(str) {
      this.snackbar = true;
      function listener(e) {
        e.clipboardData.setData("text/plain", str);
        e.preventDefault();
      }
      document.addEventListener("copy", listener);
      document.execCommand("copy");
      document.removeEventListener("copy", listener);
    },
    closeAndUpdate() {
      this.dialog = false;
      this.$emit('update');
    },
  },
  watch: {
    dialog (val) {
      !val && this.$emit('update');
    }
  }
};
</script>