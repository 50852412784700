import { createApp } from 'vue';
import App from './js/src/pages/index/App.vue';
import router from "./js/src/plugin/router";
import vuetify from "./js/src/plugin/vuetify";
import './styles/app.css';
import tools from "./js/tools";

const app = createApp(App);
app.use(router);
app.use(vuetify)
app.mount('#app');
tools.mountComponent('index', app);