<template>
  <v-dialog v-model="dialog" width="1100">
    <template v-slot:activator="{ on }" >
      <v-list-item v-bind="on" @click.stop="loadData()">
        <v-list-item-media class="mx-auto">
          <v-icon color="blue">mdi-calendar-multiple-check</v-icon>
        </v-list-item-media>
      </v-list-item>
    </template>

    <!-- INFO QUAND CLIQUE -->
    <v-card>
      <v-card-title>
        <span>
          <v-icon color="blue">mdi-calendar-multiple-check</v-icon>
          Tâches en cours pour : {{urlSite}}
        </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text v-if="tasks.length == 0">
        <p>
          Chargement en cours ...
        </p>
      </v-card-text>
      <v-card-text v-else>
        <v-card v-if="tasks['todo-items'].length ==0 ">
          <v-card-text>
            Aucune tâche en cours.
          </v-card-text>
          <v-card-actions>
            <v-btn
              :href="'https://kairios.teamwork.com/#/projects/'+teamworkId+'/tasks'"
              target="_blank"
            >
              Ajouter des tâches
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-else
          class="mb-3"
          elevation="2"
          v-bind="tasks"
          v-bind:key="task.id"
          v-for="task in tasks['todo-items']">
          <v-card-title>{{task.content}}</v-card-title>
          <v-card-text>
            {{task.description}}
          </v-card-text>
          <v-card-subtitle>
            <span class="font-weight-bold">status</span> : {{task.status}}
          </v-card-subtitle>
          <v-card-actions>
            <v-btn
              :href="'https://kairios.teamwork.com/#/projects/'+teamworkId+'/tasks'"
              target="_blank"
            >
              Voir les taches
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-btn class="v-btn__content" @click="this.dialog = false">FERMER</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  name: "Tasks",
  props: {
    urlSite: {
      type: String,
      default: null
    },
    teamworkId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      baseURL: "/v2/task/",
      dialog: false,
      tasks: ''
    };
  },
  methods: {
    loadData(){
      this.tasks= ''; // reset en cas de réouverture
      axios
        .get(this.baseURL+this.teamworkId)
        .then((response) => {
            this.tasks = response.data;
        })
        .catch(this.logResponseErrors);
      this.dialog = true;
    }
  }
};
</script>