import { createApp } from 'vue';

export default {
  /**
   * Monte un composant Vue sur l'id passé en paramètre.
   *
   * @param {String} id - Id de l'élément HTML
   * @param {Object} component - Composant Vue à monter
   * @returns {Promise<any>}
   */
  mountComponent: function(id, component) {
    return new Promise(resolve => {
      const element = document.getElementById(id);
      if (element) {
        const app = createApp(component);

        // Si vous avez un store (Vuex), l'attacher à l'application
        if (!window.Telediag) {
          window.Telediag = {};
        }
        window.Telediag[id] = app;

        app.mount(`#${id}`);
      }

      resolve();
    });
  },

  /**
   * Récupère un objet de composants à monter avec comme clé l'id de l'élément HTML et comme valeur le composant Vue à monter
   *
   * @param {Object} components - Tableau de composants à monter {<String>id : <VueComponent>component}
   */
  mountVueInstance: function(components = {}) {
    Promise.all(Object.keys(components).map(id => this.mountComponent(id, components[id])))
        .then(() => {})
    ;
  },
};
