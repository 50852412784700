<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="types"
            :loading="loading"
            loading-text="Chargement... Veuillez patienter"
            sort-by="id"
            class="elevation-1"
            :footer-props="{
                itemsPerPageAllText: 'Tout',
                itemsPerPageText: 'Lignes par page :',
            }"
        >
            <template v-slot:top>
            <v-toolbar
                flat
            >
                <v-toolbar-title>Gestion des types d'absences</v-toolbar-title>
                <v-divider
                class="mx-4"
                inset
                vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                v-model="dialog"
                max-width="600px"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    >
                    Ajouter un type d'absence 
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                    <v-container style="padding:0px">
                        <v-row>
                        <v-col
                            cols="12"
                        >
                            <v-text-field
                            v-model="editedItem.name"
                            label="Type d'absence"

                            ></v-text-field>
                        </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                    >
                        Annuler
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                    >
                        Enregistrer
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="530px">
                <v-card>
                    <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer ce type ?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Oui</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteItem(item)"
            >
                mdi-delete
            </v-icon>
            </template>
            <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="initialize"
            >
                Reset
            </v-btn>
            </template>
            <template slot="footer.page-text" slot-scope="item">
            {{item.pageStart}}-{{item.pageStop}} sur {{item.itemsLength}}
            </template>
        </v-data-table>
        <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :color="color"
            absolute
            top
            >
            {{ text }}
        </v-snackbar>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "gestionType",
        data: () => ({
            dialog: false,
            loading: true,
            dialogDelete: false,
            headers: [
                { text: 'Type d\'absence', value: 'name', sortable: false },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            types: [],
            editedIndex: -1,
            editedItem: {
                name: '',
            },
            defaultItem: {
                name: '',
            },
            color: '',
            snackbar: false,
            text: '',
            timeout: 4000,
        }),

        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Ajouter un type d\'absence' : 'Modifier le type d\'absence'
            },
        },

        watch: {
            dialog (val) {
                val || this.close()
            },
            dialogDelete (val) {
                val || this.closeDelete()
            },
        },

        mounted() {
            // Vérification du role ADMIN
            axios
            .get('/v2/agenda/isadmin')
            .then((response)=>{
                if(
                    !response.data.success // erreur dans la récupération du role
                    || (response.data.success && !response.data.message) // vérification du role ADMIN de l'utilisateur
                    ){
                    // redirection vers agenda
                    this.$router.push('/agenda');
                }
            })
            this.updateTable();
        },

        methods: {
            updateTable() {
                this.loading = true
                axios
                    .get("/v2/agenda/type")
                    .then((response) => {
                        this.loading = false
                        if(response.data.success){
                            this.types = response.data.message;
                            this.color = "success"
                            this.text = "Toutes les données ont bien été chargées !"
                            this.snackbar = true;
                        }else{
                            this.color = "warning"
                            this.text = response.data.message
                            this.snackbar = true;
                        }
                        
                    })
                    .catch(this.logResponseErrors);
            },
            
            editItem (item) {
                this.editedIndex = this.types.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem (item) {
                this.editedIndex = this.types.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            deleteItemConfirm () {
                axios
                .post("/v2/agenda/type/delete/"+this.editedItem.id)
                .then((response) => {
                    if(response.data.success){
                        this.updateTable();
                        this.closeDelete()
                    }
                })
                .catch(this.logResponseErrors);
                this.types.splice(this.editedIndex, 1)
            },

            close () {
                this.dialog = false
                this.editedIndex = -1
            },

            closeDelete () {
                this.dialogDelete = false
                this.editedIndex = -1
            },

            save () {
                if (this.editedIndex > -1) {
                    Object.assign(this.types[this.editedIndex], this.editedItem)
                    let data = new FormData();
                    data.set("name", this.editedItem.name);
                    axios
                    .post("/v2/agenda/type/update/"+this.editedItem.id, data)
                    .then((response) => {
                        if(response.data.success){
                            this.updateTable();
                            this.close()
                        }
                    })
                    .catch(this.logResponseErrors);
                } else {
                    let data = new FormData();
                    data.set("name", this.editedItem.name);
                    axios
                    .post("/v2/agenda/type/create", data)
                    .then((response) => {
                        if(response.data.success){
                            this.updateTable();
                            this.close()
                        }
                    })
                    .catch(this.logResponseErrors);
                }
                
            },
            
            logResponseErrors(err) {
                this.color = "warning"
                this.text = err
                this.snackbar = true;
            },
        },
    }
</script>