<template>
  <div>
    <v-data-table
      :headers="headersForHours"
      :items="hours"
      :items-per-page="5"
      :loading="loading"
      sort-by="date"
      :sort-desc="true"
      loading-text="Chargement... Veuillez patienter"
      class="elevation-1"
      :footer-props="{
        itemsPerPageAllText: 'Tout',
        itemsPerPageText: 'Lignes par page :',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Gestion de vos heures supplémentaires</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogHours" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2 mr-5" v-bind="attrs" v-on="on">Heures supplémentaires</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Gérer vos heures supplémentaires</span>
              </v-card-title>
              <v-card-text>
                <v-container style="padding:0px">
                  <v-row class="mt-4">
                    <v-col cols="5">
                      <v-menu
                        v-model="menuHoursDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="demande.startDate" label="Quel jour ?" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="demande.startDate" no-title scrollable @input="menuHoursDate = false" :first-day-of-week="1" locale="fr-fr" :allowed-dates="allowedDates"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field v-model="demande.hours" label="Nombre d'heures supplémentaires" clearable hide-details single-line></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea rows="2" v-model="demande.description" label="Commentaire"></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeHours">Annuler</v-btn>
                <v-btn color="blue darken-1" text @click="saveHours">Valider</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.date`]="{ item }">{{item.date|formatDate}}</template>
      <template v-slot:[`item.nbHours`]="{ item }">{{item.hours}}</template>
      <template v-slot:[`item.description`]="{ item }" >
        <p class="mt-4" v-if="item.description==''">Pas de commentaire</p>
        <p class="mt-4" v-else>{{item.description}}</p>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color" absolute top >{{ text }}</v-snackbar>
  </div>
</template>

<script>
  import axios from "axios";

  export default {
    data () {
      return {
        loading: true,
        color: '',
        snackbar: false,
        text: '',
        timeout: 4000,
        menuHoursDate: false,
        menuStartDate: false,
        dialogHours: false,
        raisonDelete: '',
        headersForHours: [
          { text: 'Date', align: 'start', value: 'date', },
          { text: 'Commentaire', value: 'description', sortable: false },
          { text: 'Nombres d\'heures supplémentaires ce jour', value: 'nbHours', sortable: false },
        ],
        demandes: [],
        hours: [],
        demande: {
          hours: '',
          startDate: new Date().toISOString().substr(0, 10),
          description: '',
        }
      }
    },

    mounted() {
      this.updateTableHours();
      axios
        .get("/v2/agenda/type")
        .then((response) => {
            if(response.data.success){
                this.types = response.data.message;
            }else{
                this.color = "warning"
                this.text = "Erreur au chargement. Merci de recharger la page."
                this.snackbar = true;
            }
            
        })
        .catch(function(error){console.log(error)});
    },

    methods: {
      updateTableHours() {
        this.loading = true
        axios
          .get("/v2/heures/list/hours")
          .then((response) => {
            this.loading = false
            if(response.data.success){
              this.hours = response.data.message;
              this.color = "success"
              this.text = "Toutes les données ont bien été chargées !"
              this.snackbar = true;
            }else{
              this.color = "warning"
              this.text = response.data.message
              this.snackbar = true;
            }
          })
          .catch(this.logResponseErrors);
      },

      closeHours() {
        this.dialogHours = false
      },

      saveHours() {
        if(isNaN(this.demande.hours)){
          this.color = "warning"
          this.text = "Veuillez rentrer un nombre pour le nombre d'heures supplémentaires"
          this.snackbar = true;
        }else{
            let data = new FormData();
            Object.keys(this.demande).forEach(key=>{
              data.set(key,this.demande[key])
            })
            axios
              .post("/v2/heures/create-hours", data)
              .then((response) => {
                if(response.data.success){
                  this.closeHours()
                  this.updateTableHours();
                  this.color = "success"
                  this.text = "Heures supplémentaires ajoutées"
                  this.snackbar = true;
                }else{
                  this.color = "warning"
                  this.text = response.data.message
                  this.snackbar = true;
                }
              })
              .catch(this.logResponseErrors);
        }
      },

      allowedDates(val){
        return parseInt((new Date(val)).getDay()) % 6 != 0;
      },
    },
  }
</script>
