<template>
  <v-row>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-chip v-on="on" label :color="getColorByTime(time)"  @click.stop="loadData()">{{ year }} : {{ time }}</v-chip>
      </template>
      <span>{{ year-1 }} : {{ lastTime }}</span>
    </v-tooltip>

    <v-dialog v-model="dialog" width="300">
      <v-card>
        <v-card-title class="headline">Historique des heures</v-card-title>

        <!-- Tant que length == 0 => données pas encore chargées  -->
        <v-card-text v-if="listeHeure.length == 0">
          <p>
            Chargement en cours ...
          </p>
        </v-card-text>
        <!-- Quand les données sont chargées -->
        <v-card-text v-else>
          <v-list>
            <v-list-item>
              <!-- Pour chaque données -->
              <v-list-item
                v-bind="heures"
                v-bind:key="heures.year"
                v-for="heures in listeHeure">
                <v-list-item>
                  <v-list-item-title><span class="font-weight-bold">{{heures.year}}</span> : {{heures.timeString}}</v-list-item-title>
                </v-list-item>
              </v-list-item>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false" >Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "Hours",
  data () {
      return {
        dialog: false,
        listeHeure: [],
      }
    },
  props: {
    time: {
      type: String,
      default: null,
    },
    lastTime: {
      type: String,
      default: null,
    },
    year: {
      type: Number,
      default: null,
    },
    teamworkId: {
      type: Number,
      default: null,
    }
  },

  methods: {
    getColorByTime(time) {
      if (time == "Erreur chargement") return "error";

      const length = time.length;
      let heuresTeamwork = "",
        heuresContrat = "";

      const firstH = time.indexOf("h", 0);
      for (let index = 0; index < firstH; index++) {
        heuresTeamwork += time[index];
      }

      const slash = time.indexOf("/", firstH + 1);
      const secondH = time.indexOf("h", firstH + 1);
      for (let index = slash + 2; index < secondH; index++) {
        heuresContrat += time[index];
      }

      if (parseInt(heuresTeamwork) > parseInt(heuresContrat)) return "error";
      else if (parseInt(heuresTeamwork) > parseInt(heuresContrat) * 0.9)
        return "warning";
      else return "success";
    },

    logResponseErrors(err) {
      if (axios.isCancel(err)) {
        console.log("Request cancelled");
      } else console.error(err);
    },

    loadData(){
      this.dialog = true;
      this.listeHeure = []; //reset si on rouvre le meme dialog
      // On va récupéré toute les heures
      axios
        .get('/v2/timeproject/'+this.teamworkId) 
        .then((response) => {
          let tempTimeResum=[];
          response.data.forEach(time => {
            let year = (time.dateCreated.split('-'))[0]; // On récupère l'année du time récupèré
            if(typeof tempTimeResum[year] == "undefined"){
              tempTimeResum[year]=0;
            }
            tempTimeResum[year] += time.minutes
            
          });
          
          tempTimeResum.forEach((el,key) => {
            if(typeof el == "number" && el>0){
              this.listeHeure.push({
                year: key,
                timeString: this.minutesToHours(el),
              });
            }
          });
        })
        .catch(this.logResponseErrors);
        
    },

    // Converti des minutes en format string "XXhYY"
    minutesToHours(totalMinutes){
      let hours = 0;
      let minutes = 0;
      if(!isNaN(totalMinutes)){
        hours = Math.floor(totalMinutes / 60);
        minutes = totalMinutes % 60;
      }
      if (minutes < 10) minutes = "0" + minutes;
      return hours + "h" + minutes ;
    },
  },
};
</script>