<template>
  <v-container>
    <div class="jumbotron">
      <h1 class="display-3 mb-4">Bienvenue sur l'application <b>Kairios</b></h1>
      <p class="lead">Sélectionner une section du site sur laquelle vous voulez vous rendre.</p>
      <hr class="my-4">
      <v-row style="margin-top: 3%" justify="center" align="center">
        <v-col cols="5">
          <v-card class="d-flex flex-column align-center text-center">
            <v-card-title style="font-size:40px"><v-icon style="font-size:50px" class="mr-4">mdi-format-list-bulleted</v-icon>Hosting</v-card-title>
            <v-card-text>
              <p class="mt-3" style="font-size:18px">Listing et gestion de toutes les informations des sites en maintenance.</p>
              <a href="/#/sites"><button type="button" style="color:white" class="btn btn-primary btn-lg mt-4">Me rendre sur Hosting</button></a>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "home",

};
</script>